<template>
  <fragment>
    <div class="tlw-mb-6 tlw-text-sm">
      {{ deck[currentIndex] }}
    </div>
    <div class="tlw-flex">
      <hs-button variant="purple" class="tlw-p-2" @click="previous">
        <hs-icon icon="arrow-left" />
      </hs-button>
      <div class="tlw-flex-1">
        <div class="tlw-flex tlw-justify-center tlw-items-center tlw-content-center tlw-h-full">
          <button
            v-for="(text, index) in deck"
            :key="`slide-btn-${index}`"
            :class="`point ${index == currentIndex ? 'current' : ''} tlw-text-lg`"
            @click="currentIndex = index"
          />
        </div>
      </div>
      <hs-button variant="purple" class="tlw-p-2" @click="next">
        <hs-icon icon="arrow-right" />
      </hs-button>
    </div>
  </fragment>
</template>

<script>
export default {
  name: 'TextSlider',
  props: {
    deck: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  methods: {
    previous() {
      this.currentIndex = Math.max(this.currentIndex - 1, 0);
    },
    next() {
      this.currentIndex = Math.min(this.currentIndex + 1, this.deck.length - 1);
    },
  },
};
</script>

<style lang="scss">
.point {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #cfcfcf;
  margin: auto 3px;
}
.current {
  background-color: #7427f1;
}
.point:first-child {
  margin-left: 0;
}
.point:last-child {
  margin-right: 0;
}
</style>
