<template>
  <div class="collapse-content">
    <div class="tlw-bg-white tlw-shadow-grey tlw-px-8 tlw-py-6 tlw-rounded-md">
      <button
        block
        v-b-toggle="id"
        class="btn-collapse tlw-pb-0 tlw-text-left tlw-items-center tlw-rounded-t-md tlw-w-full"
      >
        <hs-icon class="icon text-dark" variant="light" icon="chevron-up" />
        <div class="tlw-flex tlw-w-full tlw-justify-between">
          <div class="tlw-text-black tlw-text-base title tlw-font-semibold tlw-ml-4">
            {{ visible ? 'Produto/Oferta' : title }}
          </div>
        </div>
      </button>
      <b-collapse :id="id" v-model="visible" accordion="program-product-detail-accordion">
        <div class="b-collapse tlw-relative tlw-pl-8 tlw-pt-4">
          <slot></slot>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: this.collapsed,
    };
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    id() {
      return this._uid.toString();
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler(value) {
        this.$emit('opened', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  transition: 0.3s transform ease-in-out;
}

.collapsed .icon {
  transform: rotate(180deg);
}

.btn-collapse {
  display: flex;

  &:active {
    background-color: $white;
  }
  &:active:focus {
    box-shadow: none !important;
  }
}
</style>
