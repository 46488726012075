import { currency } from '@/shared/helpers/general';

export const BLOCK_ON_PROGRAM_FORM = 'block_on_program_form';
export const BLOCK_ON_PROGRAM_TAB_CHANGED = 'block_on_program_tab_changed';

export const FIRST_CLICK = 'first_click';
export const LAST_CLICK = 'last_click';

export const displayOffer = offer => {
  return `${offer.title || 'Oferta'} (${currency(offer.price / 100, true)})`;
};

export const displayCommissionType = type => {
  return {
    [FIRST_CLICK]: 'Por primeiro clique',
    [LAST_CLICK]: 'Por último clique',
    '': '',
  }[type];
};
