import httpClient from '@/sparkaffiliates/services/http_client.js';
import { paginationFromHTTPHeader } from '@/sparkaffiliates/helpers/paginationHelper';
import { buildParams } from '@/sparkaffiliates/helpers/serviceParamsHelper';

export default {
  getOrganizationAffiliations(params = {}) {
    const searchParams = buildParams(params, ['term', 'status', 'program_id', 'sort_by', 'order', 'page', 'items']);
    const url = `producer_api/v1/affiliations?${searchParams}`;

    return httpClient.get(url).then(({ data, headers }) => {
      return {
        affiliations: data,
        ...paginationFromHTTPHeader(headers),
      };
    });
  },
  getAffiliateAffiliations(params = {}) {
    const searchParams = buildParams(params, [
      'status',
      'program_name',
      'program_id',
      'sort_by',
      'order',
      'page',
      'items',
    ]);
    const url = `affiliate_api/v1/affiliations?${searchParams}`;

    return httpClient.get(url).then(({ data, headers }) => {
      return {
        affiliations: data,
        ...paginationFromHTTPHeader(headers),
      };
    });
  },
  updateProducerAffiliationStatus(id, status) {
    return httpClient.put(`producer_api/v1/affiliations/${id}/status/${status}`);
  },
  updateAffiliateAffiliationStatus(id, status) {
    return httpClient.put(`affiliate_api/v1/affiliations/${id}/status/${status}`);
  },
  getFacebookTracking(payload) {
    return httpClient.post(`facebook_tracking`, payload);
  },
};
