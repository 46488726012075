<template>
  <div
    class="search tlw-grid tlw-grid-cols-1 md:tlw-grid-flow-col md:tlw-grid-cols-4 md:tlw-auto-cols-max tlw-gap-4 tlw-px-4 md:tlw-p-0"
  >
    <div class="d-none d-md-block">
      <label>Buscar</label>
      <hs-input
        :value="nameSearchTerm"
        id="programSearch"
        type="search"
        placeholder="Buscar pelo nome do programa"
        @input="$emit('input-search-term', $event)"
      />
    </div>

    <div
      class="d-md-none mt-1 mb-1 search-input border-0 tlw-flex tlw-items-center tlw-bg-white tlw-border tlw-rounded-sm tlw-mr-3"
    >
      <hs-icon icon="search" class="tlw-ml-3" />
      <hs-input
        :value="nameSearchTerm"
        id="programSearch"
        type="search"
        placeholder="Buscar pelo nome do programa"
        class="tlw-border-0"
        @input="$emit('input-search-term', $event)"
      />
    </div>

    <div class="d-none d-md-block">
      <label class="d-none d-md-block">Ordenar por</label>
      <hs-multiselect
        placeholder="Ordenar por"
        track-by="id"
        label="title"
        :value="sortByValue"
        :options="sortOptions"
        :showLabels="true"
        :searchable="false"
        :multiple="false"
        :allow-empty="false"
        @select="$emit('selected-ordering', $event)"
      />
    </div>
    <div class="d-none d-md-block">
      <label class="d-none d-md-block">Filtrar por</label>
      <hs-multiselect
        placeholder="Programas cadastrados"
        track-by="id"
        label="title"
        :value="filterByProgram"
        :options="programOptions"
        :showLabels="true"
        :searchable="false"
        :multiple="false"
        :allow-empty="true"
        @select="$emit('selected-program', $event)"
      />
    </div>

    <div class="tlw-flex tlw-flex-wrap tlw-content-end">
      <MButton
        id="clearFilter"
        variant="secondary"
        :label="$t('components.filters.selected-filters.clear-filters')"
        @click="$emit('clear-filter')"
        class="d-none d-md-block"
      />
    </div>
  </div>
</template>

<script>
import MButton from '@/shared/components/MButton';
export default {
  name: 'ProgramFilter',
  components: {
    MButton,
  },
  props: {
    nameSearchTerm: { type: String },
    sortOptions: { type: Array },
    sortByValue: { type: Object },
    programOptions: { type: Array },
    filterByProgram: { type: Object },
  },
};
</script>

<style lang="scss" scoped>
.search-input {
  min-width: 270px;
  border: 1px solid #bababa !important;
  border-radius: 4px;
  /deep/ .hs-input input {
    border: 0;
    font-size: 0.875rem;
  }
}
</style>
