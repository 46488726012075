<template>
  <hsModal :id="modalId" size="md" :hide-header="true" :no-close-on-backdrop="true" :no-close-on-esc="true">
    <div class="confirmation-alert-modal__container tlw-flex tlw-justify-center tlw-pt-8 tlw-pb-2">
      <div class="confirmation-alert-modal__content tlw-grid tlw-self-center tlw-justify-items-center tlw-text-center">
        <img class="tlw-pb-10" :src="alertIcon" alt="Alerta de confirmação de operação" />
        <div class="confirmation-alert-modal__title tlw-font-bold tlw-text-2xl tlw-pb-4">
          {{ title }}
        </div>
        <div class="confirmation-alert-modal__subtitle tlw-pb-6">
          {{ subtitle }}
        </div>
        <hs-button class="tlw-mb-4 tlw-w-4/5" :variant="continueButtonVariant" @click="confirmOperation">
          {{ continueButtonText }}
        </hs-button>
        <hs-button class="tlw-w-4/5" :variant="cancelButtonVariant" @click="cancelOperation">
          {{ cancelButtonText }}
        </hs-button>
      </div>
    </div>
  </hsModal>
</template>
<script>
import { hsModal } from '@/components';

export default {
  name: 'ConfirmationModal',
  components: {
    hsModal,
  },
  props: {
    modalId: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    subtitle: {
      required: true,
      type: String,
    },
    continueButtonText: {
      required: true,
      type: String,
    },
    cancelButtonText: {
      required: true,
      type: String,
    },
    continueButtonVariant: {
      default: 'primary',
      type: String,
    },
    cancelButtonVariant: {
      default: 'outline-primary',
      type: String,
    },
    alertIcon: {
      default: require('@/assets/images/SparkAffiliates/Programs/activation-warning-robot.svg'),
    },
  },
  methods: {
    confirmOperation() {
      this.$emit('confirmOperation');
      this.$bvModal.hide(this.modalId);
    },
    cancelOperation() {
      this.$emit('undoToggleChange');
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmation-alert-modal__content {
  width: 408px;
}
</style>
