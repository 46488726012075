<template>
  <div v-bind="[$attrs, $props]" :class="`tlw-p-6 card card-${theme}`">
    <div><slot /></div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    theme: {
      type: String,
      default: 'light',
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(63, 63, 63, 0.08);
}

.card-light {
  background-color: #fff;
  color: #262626;
}

.card-dark {
  background-color: #262626;
  color: #fff;
}
</style>
