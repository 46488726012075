export default {
  data() {
    return {
      pagination: {
        currentPage: 1,
        totalCount: 0,
        perPage: 5,
      },
    };
  },
  methods: {
    initiatePagination(updatePaginationFn) {
      this.updatePage = page => {
        this.pagination.currentPage = page;
        updatePaginationFn();
      };
    },
  },
};
