import httpClient from '@/sparkaffiliates/services/http_client.js';
import { paginationFromHTTPHeader } from '@/sparkaffiliates/helpers/paginationHelper';
import { buildParams } from '@/sparkaffiliates/helpers/serviceParamsHelper';

function format({ data, headers }) {
  return {
    affiliationInvites: data,
    ...paginationFromHTTPHeader(headers),
  };
}

const getInvites = (baseURL, params) => {
  const searchParams = buildParams(params, ['term', 'program_id', 'status', 'sort_by', 'order', 'page', 'items']);
  return httpClient.get(`${baseURL}?${searchParams}`).then(format);
};

export default {
  invite(invite) {
    return httpClient.post('producer_api/v1/affiliation_invites/invite_new_affiliate', { invite });
  },
  resendEmail(inviteId) {
    return httpClient.post(`producer_api/v1/affiliation_invites/${inviteId}/resend-email`);
  },
  getOrganizationAffiliationInvites(params = {}) {
    return getInvites('producer_api/v1/affiliation_invites', params);
  },
  getAffiliateAffiliationInvites(params = {}) {
    return getInvites('affiliate_api/v1/affiliation_invites', params);
  },
  updateAffiliateAffiliationInviteStatus(id, status) {
    const url = `affiliate_api/v1/affiliation_invites/${id}/status/${status}`;

    return httpClient.put(url);
  },
};
