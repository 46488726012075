<template>
  <div class="tlw-flex tlw-flex-col">
    <div class="tlw-flex tlw-justify-end">
      <div v-if="canEdit" class="program-item__card-enable tlw-flex tlw-gap-x-3 mr-2 mb-3 tlw-flex-row">
        <div class="tlw-text-gray-500 tlw-font-semibold">
          {{ $t('sparkaffiliates.programs-list.program-details.status') }}
        </div>
        {{ enabledProgram ? 'Desativar' : 'Ativar' }}
        <MSwitch @change="updateProgramStatus" :id="`toggle-detail-${program.id}`" v-model="enabledProgram" />
      </div>
    </div>
    <div class="program-details__content tlw-grid tlw-gap-6">
      <div class="program-details__definitions-info tlw-bg-white tlw-shadow-lg tlw-rounded-lg tlw-p-8">
        <div class="tlw-flex tlw-justify-between">
          <div class="tlw-font-bold tlw-text-xl tlw-mb-6">
            {{ $t('sparkaffiliates.programs-list.program-details.definitions.title') }}
          </div>
          <div
            v-if="canEdit"
            @click="goToProgramEdit('definitions')"
            class="program-details__edit-definitions tlw-cursor-pointer tlw-mr-2"
          >
            <hs-icon icon="pencil-alt" />
          </div>
        </div>
        <div class="tlw-font-semibold tlw-mb-1">
          {{ $t('sparkaffiliates.programs-list.program-details.definitions.name') }}
        </div>
        <div class="tlw-text-sm tlw-mb-4">{{ program.name }}</div>
        <div class="tlw-font-semibold tlw-mb-1">
          {{ $t('sparkaffiliates.programs-list.program-details.definitions.description') }}
        </div>
        <div class="tlw-text-sm tlw-mb-4" v-html="program.description" />
        <div class="tlw-font-semibold tlw-mb-1">
          {{ $t('sparkaffiliates.programs-list.program-details.definitions.rules') }}
        </div>
        <div class="tlw-text-sm tlw-mb-4" v-html="program.rules" />
      </div>
      <div class="program-details__expiration-info tlw-bg-white tlw-shadow-lg tlw-rounded-lg tlw-p-8">
        <div class="tlw-font-bold tlw-text-xl tlw-mb-6">
          {{ $t('sparkaffiliates.programs-list.program-details.definitions.expiration-date') }}
        </div>
        <div class="tlw-font-semibold tlw-mb-3">
          Programa {{ program.expiration_date ? 'com' : 'sem' }} data de expiração definida
        </div>
        <div v-if="program.expiration_date" class="tlw-text-sm">{{ getFormatedDate }}</div>
      </div>
      <div class="program-details__products-info tlw-bg-white tlw-shadow-lg tlw-rounded-lg tlw-p-8">
        <div class="tlw-font-bold tlw-text-xl tlw-mb-6">
          {{ $t('sparkaffiliates.programs-list.program-details.definitions.details') }}
        </div>
        <ProductDetailCard
          :can-edit="canEdit"
          v-for="(product, index) in program.program_products"
          :key="product.sparkecommerce_product_id"
          :visible="index < 1"
          :program="program"
          :product="product"
          class="tlw-mb-6"
          @goToProgramEdit="goToProgramEdit"
        />
      </div>
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
import ProductDetailCard from '@/sparkaffiliates/views/Producer/ProgramPage/components/ProductDetailCard.vue';
import programsService from '@/sparkaffiliates/services/programs.js';
import ToastHelper from '@/shared/helpers/toast';
import MSwitch from '@/shared/components/MSwitch.vue';
export default {
  name: 'ProgramDetailsEditor',
  components: {
    ProductDetailCard,
    MSwitch,
  },
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    program: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      enabledProgram: this.program.enabled,
    };
  },
  methods: {
    returnToProgramList() {
      this.$emit('returnToProgramList');
    },
    goToProgramEdit(step) {
      this.$router.push({
        name: 'ProgramEdit',
        params: { id: this.program.id },
        query: { step },
      });
    },
    async updateProgramStatus(status) {
      try {
        status
          ? await programsService.enableProgram(this.program.id)
          : await programsService.disableProgram(this.program.id);
        ToastHelper.successMessage(
          this.$t(`sparkaffiliates.programs-list.new-program.affiliates-panel.toast.${status}`)
        );
      } catch (e) {
        ToastHelper.dangerMessage(this.$t('sparkaffiliates.affiliates-list.toast.danger-message-program'));
      }
    },
  },
  computed: {
    getFormatedDate() {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(this.program.expiration_date).toLocaleDateString('pt-BR', options);
    },
  },
};
</script>

<style lang="scss" scoped>
.program-details__content {
  @media screen and (max-width: $screen-md) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (min-width: $screen-md) {
    grid-template-columns: 2fr 5fr;
    grid-template-areas:
      'definitions products'
      'expiration products';
  }
}

.program-details__definitions-info {
  grid-area: definitions;
}

.program-details__expiration-info {
  grid-area: expiration;
  order: 2;
}

.program-details__products-info {
  grid-area: products;
}

.program-details__edit-definitions {
  color: $cyan;
  height: fit-content;
}
</style>
