import debounce from 'lodash.debounce';

export const defaultSortValue = {
  id: 'created-at-desc',
  title: 'Programas recentes (decrescente)',
  mobileTitle: 'Ordenar por',
};

export const sortParamsDictionary = {
  'name-asc': { sort_by: 'name' },
  'name-desc': { sort_by: 'name', order: 'DESC' },
  'created-at-asc': { sort_by: 'created_at' },
  'created-at-desc': { sort_by: 'created_at', order: 'DESC' },
  'affiliation-count-asc': { sort_by: 'affiliation_count' },
  'affiliation-count-desc': { sort_by: 'affiliation_count', order: 'DESC' },
  'enabled-asc': { sort_by: 'enabled' },
  'enabled-desc': { sort_by: 'enabled', order: 'DESC' },
};

export const sortOptions = [
  { id: 'name-asc', title: 'Nome do programa (A a Z)' },
  { id: 'name-desc', title: 'Nome do programa (Z a A)' },
  { id: 'created-at-asc', title: 'Programas recentes (crescente)' },
  { id: 'created-at-desc', title: 'Programas recentes (decrescente)' },
  { id: 'affiliation-count-asc', title: 'Número de Afiliações (menor a maior)' },
  { id: 'affiliation-count-desc', title: 'Número de Afiliações (maior a menor)' },
  { id: 'last-sale-asc', title: 'Última venda (crescente)' },
  { id: 'last-sale-desc', title: 'Última venda (decrescente)' },
  { id: 'enabled-asc', title: 'Status de ativação (desativada - ativa)' },
  { id: 'enabled-desc', title: 'Status de ativação (ativa - desativada)' },
];

export default {
  data() {
    return {
      nameSearchTerm: '',
      programOptions: [],
      filterByProgram: null,
      sortOptions,
      sortByValue: defaultSortValue,
      searchParams: {},
      hasFilters: false,
    };
  },
  computed: {
    paramsBySortKey() {
      return sortParamsDictionary[this.sortByValue.id];
    },
  },
  methods: {
    initiateProgramFilter(updateFilterFn) {
      this.filterBySelectedOption = debounce(e => {
        this.sortByValue = e;
        this.searchParams = { ...this.searchParams, ...this.paramsBySortKey };
        this.hasFilters = true;
        updateFilterFn();
      }, 300);
      this.filterBySelectedProgram = debounce(e => {
        this.filterByProgram = e;
        this.searchParams.program_id = e.id;
        this.hasFilters = true;
        updateFilterFn();
      }, 300);
      this.filterByText = debounce(async searchTerm => {
        this.nameSearchTerm = searchTerm;
        this.filterByValue = null;
        this.searchParams.term = searchTerm;
        this.searchParams.program_name = searchTerm;
        this.hasFilters = true;
        updateFilterFn();
      }, 300);
    },
    clearProgramFilter() {
      this.sortByValue = defaultSortValue;
      this.nameSearchTerm = '';
      this.filterByProgram = null;
      this.searchParams = {};
      this.hasFilters = false;
    },
  },
};
