<template>
  <Collapse v-if="productInfo" :title="productInfo.title" :collapsed="visible">
    <div class="product-detail__content tlw-grid tlw-gap-4">
      <div class="product-detail__offers-list">
        <div class="tlw-flex tlw-gap-4">
          <div class="tlw-font-semibold tlw-text-sm tlw-mb-2">{{ productInfo.title }}</div>
          <div
            v-if="canEdit"
            @click="goToProgramEdit('products')"
            class="product-details__edit-definitions tlw-cursor-pointer tlw-mr-2"
          >
            <hs-icon icon="pencil-alt" />
          </div>
        </div>
        <div v-for="offer in offerInfo" :key="offer.id" class="tlw-mb-1 tlw-text-sm">
          {{ displayOffer(offer) }}
        </div>
      </div>
      <div class="product-detail__icon-image tlw-justify-self-end">
        <img :src="images.detailsIcon" />
      </div>
    </div>
    <div class="product-detail__commission">
      <div class="tlw-flex tlw-gap-4">
        <div class="tlw-font-semibold tlw-mb-4">
          {{ $t('sparkaffiliates.programs-list.program-details.product-detail-card.commission') }}
        </div>
        <div
          v-if="canEdit"
          @click="goToProgramEdit('commission')"
          class="product-details__edit-definitions tlw-cursor-pointer tlw-mr-2"
        >
          <hs-icon icon="pencil-alt" />
        </div>
      </div>
      <div class="tlw-grid tlw-grid-cols-2 tlw-gap-2 tlw-mb-4">
        <div>
          <div class="tlw-font-semibold tlw-text-sm tlw-mb-2">
            Regra de comissão
          </div>
          <hs-badge variant="purple" pill>{{ displayCommissionType(program) }}</hs-badge>
        </div>
        <div>
          <div class="tlw-font-semibold tlw-text-sm tlw-mb-2">
            {{ $t('sparkaffiliates.programs-list.program-details.product-detail-card.commission-percent') }}
          </div>
          <hs-badge variant="purple" pill>Comissão {{ product.commission }}%</hs-badge>
        </div>
      </div>
    </div>
    <div class="product-detail__media-kit">
      <div class="tlw-flex tlw-gap-4">
        <div class="tlw-font-semibold tlw-mb-4">
          {{ $t('sparkaffiliates.programs-list.program-details.product-detail-card.media-kit') }}
        </div>
        <div
          v-if="canEdit"
          @click="goToProgramEdit('media-kits')"
          class="product-details__edit-definitions tlw-cursor-pointer tlw-mr-2"
        >
          <hs-icon icon="pencil-alt" />
        </div>
      </div>
      <div class="tlw-font-semibold tlw-text-sm tlw-mb-1">Mídia kit / {{ productInfo.title }}</div>
      <div class="tlw-text-sm tlw-mb-3">
        {{ $t('sparkaffiliates.programs-list.program-details.product-detail-card.address') }}
        <a :href="product.media_kits[0].source" class="text-break address-link" target="_blank">{{
          product.media_kits[0].source
        }}</a>
      </div>
      <div class="tlw-flex tlw-gap-x-3">
        <hs-badge v-for="media_type in product.media_kits[0].media_types" :key="media_type" variant="purple" pill>
          {{ media_type }}
        </hs-badge>
      </div>
    </div>
  </Collapse>
</template>

<script>
import { mapActions } from 'vuex';
import ToastHelper from '@/shared/helpers/toast';
import Collapse from './shared/Collapse';
import { productService } from '@/services';
import { displayCommissionType, displayOffer } from '@/libs/program';

export default {
  data() {
    return {
      productInfo: null,
      offerInfo: [],
      images: {
        detailsIcon: require('@/assets/images/SparkAffiliates/Programs/newbox.svg'),
      },
    };
  },
  components: {
    Collapse,
  },
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    program: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions('product', ['getProductPaymentOptions']),
    displayCommissionType(program) {
      return displayCommissionType(_.get(program, 'commission_type', ''));
    },
    displayOffer(offer) {
      return displayOffer(offer);
    },
    async getProductInfo() {
      try {
        this.productInfo = await productService.getName(this.product.sparkecommerce_product_id);
      } catch {
        ToastHelper.dangerMessage('Erro ao carregar dados do produto. Tente novamente mais tarde.');
      }
    },
    async getActiveProductOffers() {
      const allOffers = await this.getProductPaymentOptions({ id: this.product.sparkecommerce_product_id });

      this.offerInfo = _.intersectionWith(
        allOffers,
        this.product.product_offers,
        (offer, po) => po.sparkecommerce_offer_id === offer.id
      );
    },
    goToProgramEdit(step) {
      this.$emit('goToProgramEdit', step);
    },
  },
  mounted() {
    this.getProductInfo();
    this.getActiveProductOffers();
  },
};
</script>

<style lang="scss" scoped>
.product-detail__content {
  grid-template-areas:
    'offers icon-image'
    'commission media-kit';
}

.product-detail__offers-list {
  grid-area: offers;
}

.product-detail__icon-image {
  grid-area: icon-image;
}

.product-detail__commission {
  grid-area: commission;
}

.product-detail__media-kit {
  grid-area: media-kit;
}

.product-details__edit-definitions {
  color: $cyan;
  height: fit-content;
}
.address-link {
  color: #28579e;
  text-decoration: underline;
}
</style>
