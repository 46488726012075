import debug from 'debug';

const logging = debug('hs:async_work');

const defaultProperty = 'isLoading';

export default {
  methods: {
    async withLoading(work, useProperty) {
      const property = useProperty || defaultProperty;
      try {
        this[property] = true;
        return await work();
      } catch (e) {
        logging('Async work failed:', e);
        return Promise.reject(e);
      } finally {
        this[property] = false;
      }
    },
  },
};
